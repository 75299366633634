.form__legend {
    transition: all .2s ease;

    display: block;
    margin-bottom: 1.6rem;
    padding: 0 0 .5rem;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.7rem;
    line-height: 1.35;
    color: var(--color-black);
}

.credit {
    display: inline-block;
    color: var(--color-blue);
    transition: all .2s ease;
    font-weight: bold;

    text-decoration: none;

    &::after {
        content: '';
        transition: background .3s ease;

        position: relative;
        top: -.1rem;

        display: inline-block;
        width: .6rem;
        height: .9rem;
        margin-left: .3rem;

        background-image: url(inventis-flame.svg);
        background-size: .6rem .9rem;

        @supports (mask-repeat: no-repeat) {
            background: var(--color-white);
            mask-image: url(inventis-flame.svg);
            mask-size: .6rem .9rem;
            mask-repeat: no-repeat;
        }
    }

    &:hover,
    &:focus {
        color: var(--color-blue-2);
        text-decoration: none;

        &::after {
            background: var(--color-blue-2);
        }
    }
}

/*
    This is not the file input.. this is the file label that is styles as the file input
    the actual form file input is called .form__field.-file

    This file is made because it otherwise inherits styles meant for labels
*/
.form__file {
    cursor: pointer;

    transition: all .2s ease;
    box-sizing: border-box;
    border-radius: 0;

    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    padding: 1rem 1.5rem 1.1rem;
    vertical-align: baseline;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.8;

    color: var(--color-black);
    background: var(--color-white);
    border: .1rem solid var(--color-gray_10);

    outline: none;// Firefox outline style

    @media (--screen-mini-small) {
        flex-wrap: wrap;
    }

    &:focus {
        outline: none;
        border-color: var(--color-black);
    }

    &::after {
        content: attr(data-button-text);
        transition: all .2s ease;

        cursor: pointer;
        box-sizing: border-box;
        display: block;
        margin: 0;
        padding: 1.6rem 2.8rem;
        border: 0;

        color: var(--color-white);
        background: var(--color-blue);

        font-family: var(--font-default);
        font-weight: var(--font-weight-default);
        font-size: 1.5rem;
        line-height: 1;
        text-align: center;
        text-decoration: none;
        text-transform: none;

        vertical-align: middle;
        justify-self: flex-end;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}

.form__file__label {
    display: block;
    width: 100%;
    margin-bottom: 2rem;
    transform: translateY(1.3rem);

    @media (--screen-medium-large) {
        width: auto;
        max-width: 100%;
        margin-right: auto;
        margin-bottom: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

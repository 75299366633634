.logo {
    display: block;
    width: 100%;
    max-width: 50%;
    flex-grow: 1;
    flex-shrink: 1;
    //align-self: flex-end;
    margin-bottom: 2.3rem;

    @media (--screen-medium-large) {
        max-width: 20rem;
    }

    &.-footer {
        margin-bottom: 4rem;

        @media (--screen-medium-large) {
            margin-bottom: 8rem;
        }
    }
}

address,
.address {
    font: inherit;

    &.-page-footer {
        color: var(--color-black);
        margin-bottom: 6rem;
        margin-right: 6rem;
    }

    &.-contact {
        margin-bottom: 4rem;
        font-size: 1.7rem;
        line-height: 1.88;
    }
}

.address__description {
    margin-bottom: 1.6rem;
    color: inherit;
    font-weight: bold;
}

figcaption {
    position: relative;

    box-sizing: border-box;
    display: block;
    width: calc(100% - 1.6rem);
    margin: 0 auto;
    margin-top: -3rem;
    padding: 1.5rem 2.4rem;
    clear: both;

    background: var(--color-white);
    border: .5rem solid var(--color-gray_10);

    font-family: var(--font-default);
    font-weight: var(--font-weight-default);
    font-style: italic;
    font-size: 1.4rem;
    line-height: 1.5;
    color: var(--color-black);
    text-align: left;
    
    @media (--screen-medium-large) {
        margin-top: -4.4rem;
    }

    figure.small & {
        @media (--screen-medium-large) {
            margin-top: -3.4rem;
        }
    }

    figure:not(.small):not(.medium) & {
        @media (--screen-medium-large) {
            width: calc(100% - 3.2rem);
        }
    }
}

.tag-filter {
    display: block;
    width: 100%;
    margin-bottom: 2rem;

    @media (--screen-medium-large) {
        margin-bottom: 5rem;
    }
}

.tag-filter__item {
    display: inline-block;
    margin-right: 3.8rem;
    margin-bottom: 1rem;

    font-family: var(--font-default);
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 1.23;
    color: var(--color-gray);

    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }

    &.-current {
        color: var(--color-blue);
        pointer-events: none;
    }
}

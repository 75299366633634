p {
    display: block;
    margin: 0;
    margin-bottom: 4rem;

    font-family: var(--font-default);
    font-weight: var(--font-weight-default);
    font-size: 1.7rem;
    line-height: 1.88;
    color: var(--color-black);

    &.-centered-text {
        text-align: center;
    }

    &.-summary {
        font-weight: bold;
        font-size: 2rem;
        line-height: 1.65;
    }

    &:empty {
        display: none;
    }
}

/* Image Gallery */
@import "~photoswipe/dist/photoswipe.css";
@import "~photoswipe/dist/default-skin/default-skin.css";

.image-gallery {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 5rem;

    display: block;
    column-count: 2;
    column-gap: 2rem;

    max-width: 61.1rem;
    margin-left: auto;
    margin-right: auto;

    @media (--screen-medium-large) {
        column-count: 3;
        margin-bottom: 9rem;
    }
}

.image-gallery__item {
    position: relative;

    -webkit-column-break-inside: avoid;
    break-inside: avoid;
    page-break-inside: avoid;

    display: block;
    width: 100%;
    max-width: 40rem;
    //margin-bottom: 2rem; // use padding-bottom to prevent column page break with the box-shadow
    padding-bottom: 2rem;

    &::after {
        content: '';
        transition: opacity .3s ease;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 2rem); // to compensate for padding-bottom needed above
        background: var(--color-black);
        opacity: 0;
        pointer-events: none;
    }

    &::before {
        content: '';
        transition: opacity .3s ease, background .3s ease;
        opacity: 0;

        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;

        pointer-events: none;
        transform: translate3d(-50%, -50%, 0);

        display: block;
        width: 3.8rem;
        height: 3.8rem;
        margin-top: -.5rem;

        background-image: url(zoom-magnifier.svg);
        background-size: 3.8rem 3.8rem;

        @supports (mask-repeat: no-repeat) {
            background: var(--color-white);
            mask-image: url(zoom-magnifier.svg);
            mask-size: 3.8rem 3.8rem;
            mask-repeat: no-repeat;
        }
    }

    &:hover,
    &:focus {
        &::before {
            opacity: 1;
        }
        &::after {
            opacity: .2;
        }
    }

    &.-count-box {
        position: relative;
        box-sizing: border-box;
        display: block;
        margin: 0;
        padding: 0;
        border: .2rem solid var(--color-gray_10);

        img {
            display: none;
        }

        a {
            position: relative;
            z-index: 1;

            display: block;
            width: 100%;
            padding-top: 50%;
            padding-bottom: 50%;

            font-family: var(--font-default);
            font-weight: bold;
            font-size: 3.3rem;
            line-height: 0;
            color: var(--color-blue);
            text-align: center;
            text-decoration: none;
        }

        &::before {
            display: none;
        }

        &::after {
            height: 100%;
        }
    }

    &.-hidden {
        opacity: 0;
        height: 0;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    .figure,
    a,
    img {
        transition: transform .3s ease;
        display: block;
        width: 100%;
        height: auto;
        margin: 0;
    }

    img {
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    }

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        display: block;
        width: 100%;
        max-width: 176px;
        flex: 1 0 176px;

        a {
            display: block;
            padding-right: 1rem;
        }
    }
}

.pswp {
    z-index: 9999;
}

.pswp__zoom-wrap {
    display: flex;
}

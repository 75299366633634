@custom-media --popup-mobile (width < 780px);

.popup {
    transition:
        z-index 0s .3s linear,
        max-width 0s .3s linear,
        max-height 0s .3s linear
    ;

    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;

    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 1rem 0;

    // to prevent iOS bugs:
    max-width: 0;
    max-height: 0;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        opacity: 0;
        visibility: hidden;
    }

    &.-open {
        transition: z-index 0s 0s linear;
        z-index: 9999;

        // to prevent iOS bugs:
        max-width: 100%;
        max-height: calc(100% - 2rem);
    }
}

.popup__backdrop {
    transition: opacity .3s ease;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 9000;

    display: block;
    width: 100%;
    height: 100%;
    min-height: 100vh;

    background: var(--color-gray_50);
    opacity: 0;

    .popup.-open & {
        opacity: .95;
    }
}

.popup__container {
    transition: transform .3s ease, opacity .3s ease;
    transform: scale(.8);
    opacity: 0;

    position: relative;
    z-index: 9001;

    display: block;
    width: 100%;
    max-width: 60rem;
    margin: auto;

    .popup.-open & {
        transform: scale(1);
        opacity: 1;
    }

    @media (--popup-mobile) {
        margin-top: 5rem;
        width: calc(100% - 2rem);
    }
}

.popup__close {
    transition: transform .3s ease, opacity .3s ease;
    transform: scale(1);
    opacity: 0;

    position: absolute;
    top: 10%;
    right: 10%;
    z-index: 9010;

    display: inline-flex;
    width: 5.2rem;
    height: 5.2rem;
    color: var(--color-black);
    background: var(--color-white);
    border-radius: 100%;

    font: 0/0 sans-serif;
    cursor: pointer;

    &::before {
        content: '';

        display: block;
        width: 1.4rem;
        height: 1.4rem;
        margin: auto;

        background: url(cross.svg) center center no-repeat;
        background-size: 1.4rem 1.4rem;

        transition: background .3s ease, transform .3s ease;
        transform: rotate(0);

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url(cross.svg);
            mask-repeat: no-repeat;
            mask-size: 1.4rem 1.4rem;
        }
    }

    &:hover,
    &:focus {
        transform: scale(.9);
    }

    .popup.-open & {
        transform: scale(1);
        opacity: 1;
    }

    @media (--popup-mobile) {
        top: 1rem;
        right: calc(50% - 2rem);

        width: 4rem;
        height: 4rem;
    }
}

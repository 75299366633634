.testimonial-reference-block {
    display: block;
    width: 100%;
}

.testimonial-reference-block__container {
    position: relative;
    box-sizing: border-box;
    display: block;
    width: 100%;
    max-width: calc(var(--container-width) + (var(--side-padding) * 2));
    margin: 0 auto;

    padding-left: var(--side-padding);
    padding-right: var(--side-padding);
}

.testimonial-reference-block__blockquote {
    position: relative;

    &::before {
        content: '';

        position: absolute;
        top: -2.1rem;
        left: 2.8rem;

        display: inline-block;
        width: 5.7rem;
        height: 4.3rem;

        background-image: url(quotes.svg);
        background-size: 5.7rem 4.3rem;

        @supports (mask-repeat: no-repeat) {
            background: var(--color-gray_50);
            mask-image: url(quotes.svg);
            mask-size: 5.7rem 4.3rem;
            mask-repeat: no-repeat;
        }
    }
}

.pagination {
    box-sizing: border-box;
    display: block;
    width: 100%;
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8.8rem;

    padding-left: var(--side-padding);
    padding-right: var(--side-padding);
    text-align: center;
}

.pagination__container {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    margin: 0;
    padding: 0;
}

.pagination__item {
    transition: color .3s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    height: 3.5rem;
    margin: 0;
    padding: 0;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.9;
    color: var(--color-gray_50);
    text-decoration: none;

    @media (--screen-medium-large) {
        width: 5.3rem;
        height: 5.3rem;
    }

    a {
        cursor: pointer;
        display: block;
        margin: auto;
        text-decoration: none;
        color: inherit;
        text-align: center;

        &:hover,
        &:focus {
            color: var(--color-black);
        }
    }

    &.-first {
        //
    }

    &.-last {
        //
    }

    &.-current {
        color: var(--color-black);
        background: var(--color-gray_10);
        border-radius: 100%;
    }

    &.-spacer {
        //
    }

    &.-next,
    &.-prev {
        width: 3rem;

        a {
            display: inline-flex;
            width: 3rem;
            font: 0/0 sans-serif;

            &::before {
                content: '';
                transition: background .3s ease;

                display: inline-block;
                width: 3rem;
                height: 1rem;

                background-image: url(arrow.svg);
                background-size: 3rem 1rem;

                @supports (mask-repeat: no-repeat) {
                    background: var(--color-blue);
                    mask-image: url(arrow.svg);
                    mask-size: 3rem 1rem;
                    mask-repeat: no-repeat;
                }
            }

            &:hover,
            &:focus {
                &::before {
                    background: var(--color-black);
                }
            }
        }
    }

    &.-next {
        a {
            &::before {
                transform: rotate(180deg);
            }
        }
    }
}

.inline-centered-call-to-action,
.inline-call-to-action {
    box-sizing: border-box;
    display: block;
    margin-bottom: 4rem;
    padding: 4.5rem 5rem;

    border: .1rem solid var(--color-gray_50);

    clear: both;
    float: none;
    overflow: hidden;

    > * {
        //all: inherit;
        box-sizing: content-box;
        margin: 0;
        margin-bottom: 4rem;
        padding: 0;
        border: 0;

        font-family: var(--font-default);
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 1.4;
        color: var(--color-gray_50);

        &:last-child {
            margin-bottom: 0;
        }
    }

    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6 {
        font-size: 2rem;
        line-height: 2.5;
        color: var(--color-blue);
        margin-bottom: 2rem;
    }
}

.inline-centered-call-to-action {
    border-radius: 0;
    border: .9rem solid var(--color-gray-2);
    text-align: center;
}

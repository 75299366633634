.overlay-card-overview {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: calc(var(--container-width) + (var(--side-padding) * 2));
    margin: 0 auto;

    padding-left: var(--side-padding);
    padding-right: var(--side-padding);
}

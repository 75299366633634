.plack {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 4.4rem;

    border: .9rem solid var(--color-gray_10);
    background: var(--color-white);

    &.-flat,
    &.-flat-and-centered {
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &.-no-border {
        border: none;
    }
}

.plack__content {
    box-sizing: border-box;
    display: block;
    flex: 1 1 30rem;

    margin: 2rem;

    @media (--screen-medium-large) {
        margin: 3.5rem;
    }

    .plack.-flat & {
        flex: auto;

        @media (--screen-medium-large) {
            margin: 0 3.5rem 2rem;
        }
    }

    .plack.-flat-and-centered & {
        flex: initial;
        margin: auto;
        padding: 3.5rem;
    }
}

.plack__actions {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;

    margin: 0 2rem;
    padding: 0 0 2rem;

    @media (--screen-medium-large) {
        margin: 1.5rem 3.5rem 1.5rem .5rem;
        padding: 2rem 0;
    }

    .plack.-flat & {
        flex: auto;
        margin: 2rem;
        padding: 0;

        @media (--screen-medium-large) {
            margin: 0 3.5rem;
        }
    }
}

.plack__meta {
    display: block;
    margin-bottom: .2rem;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.26;
    color: var(--color-black);
    text-transform: uppercase;
}

.plack__title {
    display: block;
    margin-bottom: 1rem;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.5;
    color: var(--color-blue);
}

.plack__description {
    display: block;
    margin-bottom: 1rem;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.8;
    color: var(--color-black);

    .plack.-flat & {
        color: var(--color-gray_50);
    }

    .plack.-flat-and-centered & {
        margin-bottom: 2.6rem;
    }
}

.plack__location {
    position: relative;
    display: block;
    margin: 3.8rem 1rem 0 0;
    padding-left: 3.2rem;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.26;
    color: var(--color-black);
    text-decoration: none;

    /*&:hover,
    &:focus {
        text-decoration: underline;
    }*/

    &::before {
        content: '';

        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        transition: background .3s ease;
        display: block;
        width: 2.2rem;
        height: 2.2rem;

        background-image: url(circled-pin.svg);
        background-size: 2.2rem 2.2rem;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url(circled-pin.svg);
            mask-size: 2.2rem 2.2rem;
            mask-repeat: no-repeat;
        }
    }
}


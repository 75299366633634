body {
    position: relative;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    width: 100vw;
    min-height: 100vh;
    margin: 0 auto;

    background-color: var(--color-white);
    word-wrap: break-word; // Don't let long words break the layout on small area's

    /*Default Font Styling*/
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.3;
    color: var(--color-black);

    &.-no-overflow {
        @media (--screen-mini-small) {
            overflow: hidden;

            @supports (-webkit-overflow-scrolling: touch) {
                -webkit-overflow-scrolling: touch;
            }
        }

        @media (--screen-medium) {
            &::after {
                content: '';
                position: fixed;
                top: 0;
                left: 0;
                z-index: 9;

                display: block;
                width: 100%;
                height: 100vh;
            }
        }
    }
}

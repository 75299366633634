.video-wrapper {
    position: relative;
    display: block;
    width: 100%;
    //max-width: 79.6rem;
    height: 100%;
    max-height: 99vh;
    margin: 0 auto;
    margin-bottom: 2rem;
    /*padding-bottom: 56.25%; !* 16:9 *!*/
    /*padding-top: 2.5rem;*/

    iframe {
        position: absolute;
        top: 0;
        left: 0;

        display: block;
        width: 100%;
        height: 100%;
    }
}

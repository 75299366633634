.overlay-card {
    position: relative;
    display: block;
    width: 100%;
    max-width: 60rem;
    min-height: 60rem;
    margin-left: 4rem;
    margin-bottom: 4rem;
}

.overlay-card__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    display: block;
    width: 100%;
    height: 100%;

    img {
        position: relative;
        z-index: 1;

        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &::after {
        content: '';

        transition: transform .3s ease;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        transform: scale(1);
        opacity: .3;

        display: block;
        width: 100%;
        height: 100%;

        background: var(--color-yellow-1, var(--color-black));

        .overlay-card.-flipped & {
            transform: scaleX(-1);
        }
    }
}

.overlay-card__content {
    position: absolute;
    left: 5.6rem;
    bottom: 2.4rem;
    z-index: 3;

    .overlay-card.-flipped & {
        left: 3rem;
    }
}


.overlay-card__meta {
    display: block;
    margin-bottom: .2rem;
    padding-left: 3.2rem;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.26;
    color: var(--color-white);
    text-transform: uppercase;
}

.overlay-card__title {
    display: block;
    margin-bottom: 2rem;
    padding-left: 3.2rem;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.26;
    color: var(--color-yellow-1, var(--color-white));

    @media (--screen-medium-large) {
        font-size: 3.8rem;
    }
}

.overlay-card__description {
    display: block;
    margin-bottom: 2rem;
    padding-left: 3.2rem;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.46;
    color: var(--color-white);
}

.overlay-card__location {
    position: relative;
    display: block;
    margin: 0 1rem 3rem 3.2rem;
    padding-left: 3.2rem;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.26;
    color: var(--color-white);
    text-decoration: none;

    &::before {
        content: '';

        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        transition: background .3s ease;
        display: block;
        width: 2.2rem;
        height: 2.2rem;

        background-image: url(circled-pin.svg);
        background-size: 2.2rem 2.2rem;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url(circled-pin.svg);
            mask-size: 2.2rem 2.2rem;
            mask-repeat: no-repeat;
        }
    }
}

.page-search {
    transition: transform .5s ease, max-height .5s ease, opacity .3s ease;
    overflow: hidden;

    position: relative;
    z-index: 1;

    box-sizing: border-box;
    display: block;
    width: 100%;
    max-width: 100%;
    max-height: 0;
    margin: 0;
    padding: 0;
    transform: translateY(-100%);
    opacity: 0;
    box-shadow: none;

    background: var(--color-gray_10);

    padding-left: var(--side-padding);
    padding-right: var(--side-padding);


    &.-open {
        transition: transform .5s ease, max-height .5s ease, opacity .3s .1s ease;

        @media (--screen-large) {
            transform: translateY(0);
            max-height: 30rem;
            opacity: 1;
        }
    }
}

.page-search__container {
    position: relative;

    display: flex;
    align-items: center;
    width: 100%;
    max-width: 51.7rem;
    margin: 0 auto;

    background: var(--color-white);

    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    padding-right: 1.2rem;

    &::before {
        $h: 1.8rem;
        content: '';

        position: absolute;
        top: calc(50% - .9rem);
        left: 1.2rem;
        z-index: 0;

        display: block;
        width: $h;
        height: $h;

        background-image: url(magnifier.svg);
        background-size: 100% $h;

        @supports (mask-repeat: no-repeat) {
            background: var(--color-blue);
            mask-image: url(magnifier.svg);
            mask-size: 100% $h;
            mask-repeat: no-repeat;
        }
    }
}

.page-search__close {
    transition: color .3s ease;

    position: absolute;
    right: -3.4rem;

    box-sizing: border-box;
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    margin: 0 0 0 1.6rem;
    padding: 0 1rem;
    border: 0;
    background: none;

    vertical-align: middle;
    cursor: pointer;

    font: 0/0 var(--font-default);
    font-weight: var(--font-weight-default);
    color: var(--color-white);
    text-decoration: none;

    &:hover,
    &:focus {
        color: var(--color-black);
    }

    &::before {
        $h: 2.2rem;
        transition: background-color .3s ease;

        content: '';

        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        display: block;
        width: 100%;
        height: $h;

        background-image: url(cross.svg);
        background-size: 100% $h;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url(cross.svg);
            mask-size: 100% $h;
            mask-repeat: no-repeat;
        }
    }
}

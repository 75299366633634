/* Figure */
.image,
.figure {
    display: block;
    width: 100%;
    margin: 0;
    margin-bottom: 4rem;

    float: none;
    clear: both;

    &::after {
        content: '';
        display: table;
        clear: both;
        float: none;
        overflow: hidden;
    }

    text-align: center;
    margin-left: auto;
    margin-right: auto;

    img {
        box-sizing: border-box;
        width: auto;
        max-width: 100%;
        margin: .5rem auto 1rem;
    }

    &.small {
        @media (--screen-medium-large) {
            max-width: 33%;
        }
    }

    &.medium {
        @media (--screen-medium-large) {
            max-width: 66%;
        }
    }

    &.image-style-align-left {
        float: left;
        clear: both;
        width: unset;
        margin-right: 3rem;
        max-width: 50%;
    }

    &.image-style-align-right {
        float: right;
        clear: both;
        width: unset;
        margin-left: 3rem;
        max-width: 50%;
    }

    &.image-style-align-left img,
    &.left {
        @media (--screen-medium-large) {
            float: left;
            clear: both;
        }
    }

    &.image-style-align-right img,
    &.right {
        @media (--screen-medium-large) {
            float: right;
        }
    }
}

figure {
    @extend .figure;
}

%form__field-reset {
    transition: all .2s ease;
    box-sizing: border-box;
    border-radius: 0;

    -webkit-appearance: none !important; // HARD reset to remove shadow in input for IOS

    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 1.4rem 1.5rem;
    vertical-align: baseline;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.8;

    color: var(--color-black);
    background: var(--color-white);
    border: .1rem solid var(--color-gray_10);

    // Firefox outline style
    outline: none;
}

[name*="password_PeopleWithAScreenReaderShouldIgnoreThis"] {
    display: none !important;
}

// FF cursor pointer bug fix
input {
    cursor: pointer;
}

[type='date'] {
    &::-webkit-datetime-edit {
        transition: all .3s ease;
        opacity: 0;
    }

    &.-has-value,
    &:focus {
        &::-webkit-datetime-edit {
            opacity: 1;
        }
    }
}

.form__field {
    @extend %form__field-reset;

    &:focus {
        outline: none;
        border-color: var(--color-black);
    }

    &::placeholder {
        color: inherit;
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
    }

    &.-page-search {
        padding: 1.2rem 1.5rem;
        padding-left: 4rem;
        border: 0;
    }

    &.-nav-search {
        padding: .6rem;
        padding-left: .9rem;
        border: 0;
        font-size: 2rem;
        line-height: 1;

        &::placeholder {
            color: var(--color-blue);
            position: relative;
            font-size: 2rem;
        }
    }

    &.-textarea {
        resize: vertical;
    }

    &.-read-only {
        pointer-events: none;
    }

    &.-select {
        padding: 1.4rem 5rem 1.4rem 1.5rem;
        cursor: pointer;

        background-image: url(arrow.svg);
        background-repeat: no-repeat;
        background-size: 1.3rem;
        background-position: right 2rem center;

        @-moz-document url-prefix() { // fix text indentation in FF in select
            text-indent: -.3rem;
        }
    }

    &.-file {
        display: none;
    }

    &[disabled] {
        background: var(--color-gray_10);
        opacity: .5;
    }

    .form__entry.-invalid & {
        border-color: var(--color-red);

        // Firefox outline style & shadow
        outline: none;
        box-shadow: none;
    }

    .form__entry.-password-strength & {
        border-radius: 1rem 1rem 0 0;
    }

    &.-has-value,
    &[data-component='Form/textarea']:focus, // this one has 'hasValue' inside it
    &[data-component='Form/hasValue']:focus {
        $shift: 1rem;
        padding-top: (1.4rem + $shift);
        padding-bottom: (1.4rem - $shift);

        & ~ .form__label {
            font-size: 1.2rem;
            transform: translateY(1rem);
        }
    }
}


.page-footer {
    flex-shrink: 0;

    display: block;
    padding-top: 3rem;
    background: var(--color-gray_10);

    @media (--screen-medium-large) {
        padding-top: 6rem;
    }
}

.page-footer__container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    width: 100%;
    max-width: calc(var(--container-width) + (var(--side-padding) * 2));
    margin: 0 auto;

    padding-left: var(--side-padding);
    padding-right: var(--side-padding);

    @media (--screen-medium-large) {
        flex-direction: row;
    }

    &.-top {
        display: block;
        padding-bottom: 7rem;
    }

    &.-main {
        padding-bottom: 4rem;
    }

    &.-bottom {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

.page-footer__title {
    display: block;
    margin-bottom: 2rem;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.9rem;
    font-style: normal;
    line-height: 1.26;
    color: var(--color-black);
    text-transform: uppercase;
}

.page-footer__side {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-basis: 30rem;
    justify-content: space-between;

    @media (--screen-mini-medium) {
        flex-basis: 100%;
    }
}

.page-footer__newsletter {
    margin-bottom: 4rem;
    margin-right: auto;
}

.page-footer__side-wrapper {

}

.page-footer__description {
    display: block;
    max-width: 40rem;

    margin-bottom: 1.6rem;
    font-family: var(--font-default);
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.5;
    color: var(--color-gray_50);
}

.page-footer__social {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 4rem;
}

.page-footer__social-title {
    display: inline-block;
    margin: 0;
    margin-right: 2.6rem;
    vertical-align: middle;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    font-style: normal;
    line-height: 1.26;
    color: var(--color-black);
    text-transform: uppercase;
}

.page-footer__social-item {
    position: relative;
    transition: background .3s ease;
    border-radius: 100%;
    display: inline-block;
    width: 2.8rem;
    height: 2.8rem;
    margin-right: .8rem;
    vertical-align: middle;
    font: 0/0 sans-serif;
    background: var(--color-blue);
    color: var(--color-white);

    &::before {
        content: '';
        transition: background .3s ease;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        display: block;
        background: var(--color-white);
        mask-repeat: no-repeat;
    }

    &.-facebook::before {
        width: .8rem;
        height: 1.5rem;
        background-image: url(facebook.svg);
        background-size: .8rem 1.5rem;
        background-color: transparent;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url(facebook.svg);
            mask-size: .8rem 1.5rem;
        }
    }

    &.-twitter::before {
        width: 1.76rem;
        height: 1.4rem;
        background-image: url(twitter.svg);
        background-size: 1.76rem 1.4rem;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url(twitter.svg);
            mask-size: 1.76rem 1.4rem;
        }
    }

    &.-instagram::before {
        width: 1.2rem;
        height: 1.2rem;
        background-image: url(instagram.svg);
        background-size: 1.2rem 1.2rem;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url(instagram.svg);
            mask-size: 1.2rem 1.2rem;
        }
    }

    &.-linkedin::before {
        width: 1.5rem;
        height: 1.5rem;
        background-image: url(linkedin.svg);
        background-size: 1.5rem 1.5rem;
        background-color: transparent;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url(linkedin.svg);
            mask-size: 1.5rem 1.5rem;
        }
    }

    &:hover,
    &:focus {
        background: var(--color-black);
    }
}

.page-footer__blocks-container {
    display: flex;
    flex-wrap: wrap;
}

.page-footer__block {
    margin-bottom: 1rem;
}

.page-footer__subtitle {
    display: block;
    max-width: 13.2rem;
    margin: 0 2.6rem 1rem 0;
    vertical-align: middle;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1rem;
    font-style: normal;
    line-height: 1.26;
    color: var(--color-black);
    text-transform: uppercase;
}

.spotlight-items-overview {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100% + 4rem);
    margin-left: -4rem;
    padding-bottom: 2.2rem;

    // Running out of time, fixing problem this way
    > *:not(.spotlight-items-overview__subcontainer) {
        max-width: 60rem;
        margin-left: 4rem;

        @media screen and (max-width: 1100px) {
            max-width: 50rem;
        }
    }
}

.spotlight-items-overview__subcontainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1 1 35%;
    margin-left: 4rem;
    max-width: 60rem;

    // Running out of time, fixing problem this way
    > * {
        margin-bottom: 4rem;
    }
}

.nav-wrapper {
    display: flex;
    flex-direction: column;

    @media (--screen-large) {
        flex-direction: row;
        justify-content: flex-end;
        order: -1;
        margin-bottom: 1rem;
    }
}

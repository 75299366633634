.image-card {
    position: relative;
    display: block;
    width: 100%;
    min-width: 28rem;
    flex: 1 1; // equals grow: 1, shrink: 1, basis: 0%;
    margin: 0 0 2rem 4rem;
    background: var(--color-gray_10);

    max-width: 39.5rem;
    margin-bottom: 4rem;

    @media (--screen-medium) {
        min-width: 35rem;
    }

    @media (--screen-large) {
        margin-bottom: 4rem;
        min-width: 30rem;
    }

    &:hover,
    &:focus {
        img {
            transform: scale(1.1);
        }

        .image-card__link {
            border-bottom-color: transparent;
        }
    }

    &.-bottom-padded {
        padding-bottom: 3.5rem;
    }

    &.-ghost {
        display: block;
        margin-bottom: 0;
        padding: 0;
        background: none;
        visibility: hidden;
    }
}

.image-card__image {
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;

    img {
        transition: transform .3s ease;
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
        overflow: hidden;
    }
}

.image-card__link {
    display: block;
}

.image-card__content {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 3rem 3.5rem .5rem;
}

.image-card__meta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
}

.image-card__meta-item {
    display: block;
    margin-right: 2.8rem;
    margin-bottom: .5rem;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2.7rem;
    color: var(--color-gray_50);
    text-transform: uppercase;

    &.-date {
        &::before {
            content: '';

            position: relative;
            top: .2rem;

            transition: background .3s ease;

            display: inline-block;
            width: 1.1rem;
            height: 1.4rem;
            margin-right: .8rem;

            background: url(calendar.svg) center center no-repeat;
            background-size: 1.1rem 1.4rem;

            @supports (mask-repeat: no-repeat) {
                background: currentColor;
                mask-image: url(calendar.svg);
                mask-repeat: no-repeat;
                mask-size: 1.1rem 1.4rem;
            }
        }
    }
}

.image-card__title {
    display: block;
    margin-bottom: 2.5rem;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.26;
    color: var(--color-blue);

    // word-break: break-all; // needed because real data showed single words exceeding width of component

    @media (--screen-medium-large) {
        font-size: 2.8rem;
    }

    a {
        display: block;
        text-decoration: none;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;

            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

.image-card__location {
    position: relative;

    display: block;
    padding-left: 2.8rem;
    margin-bottom: 2.5rem;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.26;
    color: var(--color-black);

    &::before {
        content: '';
        transition: background .3s ease;

        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        display: block;
        width: 2.2rem;
        height: 2.2rem;
        background: var(--color-black);
        border-radius: 100%;
    }

    &::after {
        content: '';
        transition: background .3s ease;

        position: absolute;
        top: 50%;
        left: .7rem;
        z-index: 1;
        transform: translateY(-50%);

        display: block;
        width: .8rem;
        height: 1.1rem;

        background-image: url(pin.svg);
        background-size: .8rem 1.1rem;

        @supports (mask-repeat: no-repeat) {
            background: var(--color-white);
            mask-image: url(pin.svg);
            mask-size: .8rem 1.1rem;
            mask-repeat: no-repeat;
        }
    }
}

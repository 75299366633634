.form__entry {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    &.-invalid {
        // see form__field.scss
        // see form__message.scss
    }

    &.-password-strength {
        // see password-strength.scss
    }
}

.section-heading {
    position: relative;
    z-index: 1;

    box-sizing: border-box;
    display: block;
    width: 100%;
    max-width: calc(82.4rem + (var(--side-padding) * 2)); // needed because of the border-box model (to set correct content width)
    margin: 0 auto;
    margin-bottom: 3rem;

    padding-left: var(--side-padding);
    padding-right: var(--side-padding);

    &.-small {
        max-width: calc(61.5rem + (var(--side-padding) * 2));
    }
}

.section-heading__back-link {
    transition: color .3s ease;

    position: relative;
    display: block;
    margin: 0;
    margin-bottom: 1.5rem;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2.2;
    color: var(--color-blue);
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;

    &::before {
        content: '';
        transition: background .3s ease;

        display: inline-block;
        width: 3rem;
        height: .9rem;
        margin-right: 1rem;

        background-image: url(arrow.svg);
        background-size: 3rem .9rem;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url(arrow.svg);
            mask-size: 3rem .9rem;
            mask-repeat: no-repeat;
        }
    }

    &:hover,
    &:focus {
        text-decoration: none;
        color: var(--color-black);
    }
}

.section-heading__title {
    display: block;
    max-width: 71.3rem;
    margin: 0 auto;
    margin-bottom: 1rem;

    font-family: var(--font-default);
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 1.2;
    color: var(--color-blue);
    text-align: center;

    @media (--screen-medium-large) {
        font-size: 5rem;
    }

    .section-heading.-small {
        margin-bottom: 1rem;
    }
}

.section-heading__sub-title {
    display: block;
    margin: 0;
    margin-bottom: 1.6rem;
    text-align: center;
}

.section-heading__description {
    display: block;
    margin: 0;
    margin-bottom: 1.8rem;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.65;
    color: var(--color-gray_50);
    text-align: center;

    .section-heading.-small & {
        font-size: 1.7rem;
        line-height: 1.58;
    }
}

.section-heading__meta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    //padding-top: 3rem;

    &.-spread-out {
        justify-content: space-evenly;
    }
}

.section-heading__meta-item {
    position: relative;
    display: block;
    margin: 0 1rem 1.5rem 1rem;
    padding-left: 2.2rem;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.26;
    color: var(--color-gray_50);

    &::before {
        content: '';

        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        transition: background .3s ease;
        display: block;
        background-color: currentColor;
        mask-repeat: no-repeat;
    }

    &.-date {
        padding-left: (1.6rem + .8rem);

        &::before {
            width: 1.6rem;
            height: 1.8rem;
            background-image: url(calendar.svg);
            background-size: 1.6rem 1.8rem;

            @supports (mask-repeat: no-repeat) {
                background: currentColor;
                mask-image: url(calendar.svg);
                mask-size: 1.6rem 1.8rem;
            }
        }
    }

    &.-time {
        padding-left: (1.8rem + .8rem);

        &::before {
            width: 1.8rem;
            height: 1.8rem;
            background-image: url(clock.svg);
            background-size: 1.8rem 1.8rem;

            @supports (mask-repeat: no-repeat) {
                background: currentColor;
                mask-image: url(clock.svg);
                mask-size: 1.8rem 1.8rem;
            }
        }
    }

    &.-price {
        padding-left: (1.8rem + .8rem);

        &::before {
            width: 1.8rem;
            height: 1.8rem;
            background-image: url(circled-euro-symbol.svg);
            background-size: 1.8rem 1.8rem;

            @supports (mask-repeat: no-repeat) {
                background: currentColor;
                mask-image: url(circled-euro-symbol.svg);
                mask-size: 1.8rem 1.8rem;
            }
        }
    }

    &.-location {
        padding-left: (2.2rem + .8rem);

        &::before {
            width: 2.2rem;
            height: 2.2rem;
            background-image: url(circled-pin.svg);
            background-size: 2.2rem 2.2rem;

            @supports (mask-repeat: no-repeat) {
                background: currentColor;
                mask-image: url(circled-pin.svg);
                mask-size: 2.2rem 2.2rem;
            }
        }
    }

    &.-category {
        text-transform: uppercase;
    }
}

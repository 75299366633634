html {
    font-size: 62.5%; // base for rem units - for ±97% of all browsers this equates to 10px
    line-height: 1;

    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    margin: 0 auto;

    background: none; // set to overwrite Firefox themes
    overflow-x: hidden;

    touch-action: manipulation; // remove 350ms delay on ios

    &.-no-overflow {
        @media (--screen-mini-small) {
            overflow: hidden;

            @supports (-webkit-overflow-scrolling: touch) {
                -webkit-overflow-scrolling: touch;
            }
        }
    }
}

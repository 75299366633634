.call-to-action-bar {
    display: block;
    background: var(--color-gray_10);
    padding-top: 5.8rem;
    padding-bottom: 3.8rem;
}

.call-to-action-bar__container {
    position: relative;

    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: calc(var(--container-width) + (var(--side-padding) * 2));
    margin: 0 auto;

    padding-left: var(--side-padding);
    padding-right: var(--side-padding);

    @media (--screen-medium-large) {
        padding-left: 11.7rem;
        padding-right: 13rem;
    }

    &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: -3.8rem;

        display: block;
        width: 30rem;
        height: 13.2rem;
        background: url(bg.svg) center center no-repeat;
        background-size: 100% 100%;

        @media (--screen-medium-large) {
            width: 46.4rem;
            height: 20.5rem;
        }
    }

    // IE11 bugfix
    @media all and (-ms-high-contrast:none) {
        justify-content: flex-start;
    }
}

.call-to-action-bar__content {
    position: relative;
    z-index: 1;

    display: block;
    margin-bottom: 2rem;
    max-width: 65rem;
    flex: 1 1 30rem;

    @media (--screen-medium-large) {
        padding-right: 5rem;
    }

}

.call-to-action-bar__title {
    display: block;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 2.8rem;
    line-height: 1.26;
    color: var(--color-black);

    @media (--screen-medium-large) {
        font-size: 3.8rem;
    }
}

.call-to-action-bar__description {
    display: block;
    margin: 0;
}

.call-to-action-bar__actions {
    position: relative;
    z-index: 1;

    margin-top: 2rem;
    margin-bottom: 2rem;
}

ul:not([class]),
ol:not([class]),
.list {
    display: block;
    margin: 0;
    margin-bottom: 4rem;
    padding: 0;
    list-style: none;

    font-family: var(--font-default);
    font-weight: var(--font-weight-normal);
    font-size: 1.7rem;
    line-height: 1.8;
    color: var(--color-black);

    li {
        position: relative;
        padding: 0;
        margin-left: 2.5rem;
        font-weight: var(--font-weight-normal);

        &::before {
            content: '\2022';
            color: var(--color-black);
            font-weight: bold;
            display: inline-block;
            margin: 0 1rem 0 -2.5rem;
            width: 1rem;
        }
    }
}

ol:not([class]) {
    li {
        counter-increment: li;

        &::before {
            content: counter(li) '.';
            font-size: 1.5rem;
            text-align: right;
            width: 4rem;
            margin-left: -5rem;
        }
    }
}

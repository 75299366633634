.item-overview {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    width: calc(100% + 4rem);
    max-width: calc(var(--container-width) + (var(--side-padding) * 2));
    margin-left: -4rem;
    margin-bottom: 5rem;

    padding-left: var(--side-padding);
    padding-right: var(--side-padding);

    &.-align-left {
        justify-content: flex-start;
    }

    @media (--screen-medium) {
        margin-bottom: 8rem;
    }

    @media (--screen-large) {
        margin-bottom: 10rem;
    }
}

article blockquote,
.blockquote {
    position: relative;
    box-sizing: border-box;
    display: block;
    width: 100%;
    background-color: var(--color-gray_10);
    margin: 6rem 0;
    padding: 4rem 3rem 3rem;
    border-bottom: .4rem solid var(--color-gray_50);

    @media (--screen-medium-large) {
        margin-left: 6rem;
        padding: 5rem 6rem;
    }

    &::before {
        content: '';

        position: absolute;
        top: -2.1rem;
        left: 2.8rem;

        display: inline-block;
        width: 5.7rem;
        height: 4.3rem;

        background-image: url(quotes.svg);
        background-size: 5.7rem 4.3rem;

        @supports (mask-repeat: no-repeat) {
            background: var(--color-gray_50);
            mask-image: url(quotes.svg);
            mask-size: 5.7rem 4.3rem;
            mask-repeat: no-repeat;
        }
    }

    p {
        display: block;
        margin: 0 0 3rem;

        font-family: var(--font-default);
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 1.3;
        color: var(--color-gray_50);

        @media (--screen-medium-large) {
            font-size: 2.6rem;
        }
    }

    ul,
    ol {
        li {
            padding-left: 1rem;
        }
    }

    footer {
        display: block;
        margin: 0;
        padding: 0;

        font-family: var(--font-default);
        font-weight: bold;
        font-size: 2.8rem;
        line-height: 1.26;
        color: var(--color-gray_50);

        @media (--screen-medium-large) {
            font-size: 3.8rem;
        }

        &::before {
            content: '—';
            margin-right: 1ch;
        }
    }
}

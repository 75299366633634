.jumbotron {
    position: relative;
    display: block;
}

.jumbotron__image {
    position: relative;
    display: block;
}

.jumbotron__popup-trigger {
    position: relative;
    z-index: 2;
    display: block;
    background: none;
    border: 0;
    cursor: pointer;
    text-align: left;
}

.jumbotron__popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;

    transition: transform .5s ease, opacity .2s;

    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    transform: scale(.8);
    opacity: 0;
    pointer-events: none;

    &.-open {
        transform: scale(1);
        opacity: 1;
        z-index: 9999;
        pointer-events: initial;
        display: flex;
    }
}

.jumbotron__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9000;

    display: block;
    width: 100%;
    height: 100%;

    background: var(--color-black);
    opacity: .5;
}

.jumbotron__popup-inner {
    position: relative;
    z-index: 9999;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 2rem;

    @media (--screen-medium-large) {
        padding: 5rem;
    }
}

.jumbotron__popup-close {
    transition: opacity .3s ease;

    position: absolute;
    top: .5rem;
    right: .5rem;
    z-index: 9999;

    background: none;
    color: var(--color-white);
    border: none;

    cursor: pointer;

    &:hover,
    &:focus {
        opacity: .8;
    }

    &::before {
        $h: 2.2rem;
        transition: background-color .3s ease;

        content: '';

        display: block;
        width: 100%;
        height: $h;

        background-image: url(cross.svg);
        background-size: 100% $h;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url(cross.svg);
            mask-size: 100% $h;
            mask-repeat: no-repeat;
        }
    }
}

.jumbotron__content {
    display: block;
    width: 100%;
    max-width: 48.3rem;

    @media (--screen-mini-medium) {
        align-self: center;
    }

    @media (--screen-large) {
        width: 50%;
        padding-top: 12.3rem;
        padding-bottom: 7.3rem;
    }
}

.jumbotron__title {
    display: block;
    margin-bottom: 1rem;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 3rem;
    line-height: 1.17;
    color: var(--color-yellow-1, var(--color-blue));

    @media (--screen-medium-large) {
        font-size: 4.5rem;
    }
}

.jumbotron__sub-title {
    display: block;
    margin-bottom: 2.4rem;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.7rem;
    line-height: 1.35;
    color: var(--color-gray-5, var(--color-blue));
}

.jumbotron__description { // Because for some reason this is a rich text field I have to do this
    > p {
        display: block;
        font-family: var(--font-default);
        font-weight: bold;
        font-size: 2.1rem;
        line-height: 1.5;
        color: var(--color-blue);
    }
}

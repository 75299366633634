.form__select {
    border: .1rem solid var(--color-gray-lighter);
    padding-right: 3.6rem;
    cursor: pointer;
    color: var(--color-black);
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
    font-size: 1.6rem;

    &:invalid {
        color: var(--color-gray-light);
    }
}

.form__select__container {
    position: relative;

    &::after {
        content: '';
        display: block;
        width: 1rem;
        height: 1rem;
        background: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCAxMCA2IiB3aWR0aD0iMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTE1ODAuMjE5NjcgMzY1Ny43ODA2NGMtLjI5Mjg5LjI3ODk0LS4yOTI4OS43MzEyIDAgMS4wMTAxNXMuNzY3NzcuMjc4OTUgMS4wNjA2NiAwbDQuNS00LjI4NTcxYy4yOTI4OS0uMjc4OTUuMjkyODktLjczMTIxIDAtMS4wMTAxNmwtNC41LTQuMjg1NzFjLS4yOTI4OS0uMjc4OTUtLjc2Nzc3LS4yNzg5NS0xLjA2MDY2IDBzLS4yOTI4OS43MzEyMSAwIDEuMDEwMTVsMy45Njk2NyAzLjc4MDY0eiIgZmlsbD0iI2FhYTM5NSIgdHJhbnNmb3JtPSJtYXRyaXgoMCAxIC0xIDAgMzY1OSAtMTU4MCkiLz48L3N2Zz4=') no-repeat center center;
        position: absolute;
        top: 50%;
        right: 1.5rem;
        transform: translateY(-50%);
    }
}

.testimonial-overview {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-bottom: 5rem;

    @media (--screen-medium) {
        margin-bottom: 8rem;
    }

    @media (--screen-large) {
        margin-bottom: 10rem;
    }
}

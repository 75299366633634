.page-header {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    max-width: calc(var(--container-width) + (var(--side-padding) * 2));
    margin: 0 auto;

    padding-top: 2rem;
    padding-left: var(--side-padding);
    padding-right: var(--side-padding);
}

.item {
    transition: transform .3s ease, background-color .3s ease;
    box-sizing: border-box;
    display: block;
    width: 100%;
    max-width: 27rem;
    flex: 1 1 27rem;
    margin-left: 3rem;
    margin-bottom: 4rem;

    text-align: center;
    text-decoration: none;

    background: var(--color-gray_10);

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.item__image {
    display: block;

    img {
        display: block;
        margin: 0 auto;
        border-radius: 100%;
    }
}

.item__title {
    display: block;
}

.item__description {
    display: block;
}

.nav-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @media (--screen-mini-medium) {
        transition:
            transform .3s ease,
            opacity .3s .1s ease;

        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;

        background: var(--color-gray_10);

        height: 100vh;
        padding: 6.8rem 6rem 1rem 3rem;

        opacity: 0;
        max-height: 100vh;
        overflow-y: auto;
    }

    @media (--screen-mini-small) {
        width: 100%;
        transform: translate3d(0, -100%, 0);
    }

    @media (--screen-medium) {
        transform: translate3d(-100%, 0, 0);
    }

    @media (--screen-large) {
        flex-shrink: 0;
        width: auto;
        margin-left: auto;
    }

    .-nav-is-open & {
        @media (--screen-mini-medium) {
            transition:
                transform .3s ease,
                opacity .3s ease;

            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }
}

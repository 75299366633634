.form {
    box-sizing: border-box;
    display: block;
    width: 100%;
    max-width: 58.8rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8rem;
    padding: 2rem;

    background: var(--color-white);
    box-shadow: 0 4px 9px 1px rgba(0,0,0,0.20);
    
    @media (--screen-medium-large) {
        padding: 3.7rem 4.4rem;
    }
}

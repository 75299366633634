.form__radio {
    border: 0;
    display: none;

    + .form__label {
        position: relative;

        &::before,
        &::after {
            content: '';
            display: block;

            position: absolute;
            top: 0;
            left: 0;
        }

        &::before {
            box-shadow: inset 0 0 0 .2rem var(--color-gray_10);
            width: 2rem;
            height: 2rem;
        }

        &::after {
            z-index: 1;
            top: .5rem;
            left: .5rem;
            width: 1rem;
            height: 1rem;
            opacity: 0;
            transition: all .2s ease;
            transform: scale(0);
            border-radius: 100%;
        }
    }

    &:checked + .form__label::before {
        box-shadow: inset 0 0 0 .2rem var(--color-blue);
    }

    &:checked + .form__label::after {
        opacity: 1;
        transform: scale(1);
    }

    & + .form__label::before {
        border-radius: 100%;
    }

    & + .form__label::after {
        background: var(--color-blue);
    }
}

.form__help-text {
    position: relative;
    box-sizing: border-box;
    display: block;

    margin-top: 0;

    font-family: var(--font-default);
    font-weight: var(--font-weight-default);
    font-size: 1.4rem;
    line-height: 1;
    color: var(--color-gray_50);
    padding: .5rem 1rem 1rem 0;
    overflow: hidden;
}

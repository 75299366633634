.layout {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    width: calc(100% + 4rem);
    margin-left: -4rem;
    margin-bottom: 8rem;
}

.layout__section {
    display: block;
    width: 100%;
    margin-left: 4rem;

    .layout.-contact & {
        flex: 1 1 50rem;

        @media (--screen-medium-large) {
            max-width: 60rem;
        }
    }
}

.copy {
    display: block;
    margin-right: 4.4rem;
    margin-bottom: 1rem;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.6rem;
    color: var(--color-blue);
}

.testimonial {
    position: relative;
    display: block;
    text-align: center;
    margin-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.testimonial__image {
    position: relative;
    display: block;
    width: 100%;

    img {
        display: block;
        width: 100%;
        height: auto;
    }
}


.testimonial__title {
    display: block;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.26;
    color: var(--color-blue);

    @media (--screen-medium) {
        font-size: 3rem;
    }

    @media (--screen-large) {
        font-size: 3.8rem;
    }

    > a {
        text-decoration: none;
        color: inherit;
    }
}

.testimonial__subtitle {
    display: block;
    margin-bottom: 3.2rem;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.26;
    color: var(--color-gray_50);
}

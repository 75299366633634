.menu-button {
    transition:
        top .3s ease,
        background-color .3s .3s ease;
    box-sizing: border-box;

    position: fixed;
    top: .5rem;
    right: .5rem;
    z-index: 9999;

    display: none;
    margin: 0;
    padding: 2rem;
    border: 0;
    outline: none;

    cursor: pointer;
    background: color-mod(var(--color-white) a(40%));
    border-radius: 100%;

    &.-active {
        transition:
            top .3s ease,
            background-color .3s 0s ease;
        background: transparent;

        @media (--screen-large) {
            right: 1.6rem;
        }
    }

    @media (--screen-mini-medium) {
        display: block;
    }

    @media print {
        display: none;
    }
}

.menu-button__hamburger {
    position: relative;
    top: .2rem;
    transition: transform .3s ease;
    display: inline-block;
    vertical-align: middle;

    .-active & {
        transform: translateX(.2rem);
    }
}

.menu-button__hamburger-inner {
    position: relative;
    transition: transform .3s ease, opacity .2s ease, background-color .2s ease;
    display: block;
    width: 1.8rem;
    height: .2rem;
    margin-bottom: .4rem;

    border-radius: .2rem;
    background: var(--color-black);

    // Arrow down starting position
    &:not(:first-child):not(:last-child) {
        &::before,
        &::after {
            transition: transform .2s 0s ease, opacity .2s 0s ease;
            content: '';
            position: absolute;
            top: 0;
            left: calc(50% - .1rem);
            width: 50%;
            height: 100%;
            background: inherit;
            opacity: 0;
        }
    }

    // Cross
    .-active & {
        background: var(--color-black);

        &:first-child {
            transform-origin: left top;
            transform: rotate(45deg);
        }

        &:last-child {
            transform-origin: left bottom;
            transform: rotate(-45deg);
        }

        // Arrow up starting position
        &:not(:first-child):not(:last-child) {
            opacity: 0;
            left: -.3rem;
        }
    }

    // arrow down
    .-arrow & {
        &:first-child,
        &:last-child {
            opacity: 0;
        }

        &:not(:first-child):not(:last-child) {
            @media (--screen-mini-small) {
                transform: rotate(90deg);
            }

            &::before,
            &::after {
                transition: transform .2s .2s ease, opacity .2s .2s ease;
                opacity: 1;
            }

            &::before {
                transform: rotate(-45deg);
                transform-origin: right;
            }

            &::after {
                transform: rotate(45deg);
                transform-origin: right;
            }
        }
    }

    // arrow up, requires the button to be active
    .-active.-arrow & {
        transition: opacity .2s 0s ease, transform .2s 0s ease;
        opacity: 0;

        // cross
        &:first-child,
        &:last-child {
            transition: opacity .2s 0s ease, transform .2s .2s ease;
            opacity: 0;
        }

        &:not(:first-child):not(:last-child) {
            opacity: 1;
            transform: rotate(-90deg);

            @media (--screen-medium) {
                transform: rotate(-180deg);
            }
        }
    }
}

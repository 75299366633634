.form__label{
    order: 2;

    display: block;
    cursor: pointer;

    color: var(--color-black);
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.5;
    padding: 0 0 .5rem;

    transition: all .2s ease;

    small {
        font-family: var(--font-sofia);
        font-weight: normal;
        font-size: 1.3rem;
        color: var(--color-gray_50);
        padding-left: .7rem;
    }

    &.-placeholder-label {
        order: 1;

        position: absolute;
        top: 0;

        box-sizing: border-box;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        padding-left: 1.6rem;
        line-height: 1;

        font-size: 1.6rem;
        transform: translateY(2.1rem);
        padding-right: 4rem;
    }

    &.-legend {
        order: 0;

        display: block;
        margin-bottom: 1.6rem;

        font-family: var(--font-default);
        font-weight: bold;
        font-size: 1.7rem;
        line-height: 1.35;
        color: var(--color-black);
    }

    &.-radio,
    &.-radio-button,
    &.-checkbox {
        display: block;
        margin: 0 0 2rem 0;
        padding: 0 0 0 (1.8rem + 1.3rem);

        font-family: var(--font-default);
        font-weight: var(--font-weight-default);
        font-size: 1.6rem;
        line-height: 1;
        color: var(--color-black);
    }

    &.-select {
        pointer-events: none;
    }
}

.link,
a:not([class]) {
    display: inline-block;
    color: var(--color-blue);
    transition: all .2s ease;

    &:hover,
    &:focus {
        color: var(--color-blue);
        text-decoration: none;
    }
}

a[href*="tel:"] {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

a,
.link {
    display: inline-block;

    &.-page-footer-tel {
        color: var(--color-gray_50);
        font-weight: bold;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &.-page-footer-bottom {
        text-decoration: none;
        margin-right: 4.4rem;
        margin-bottom: 1rem;
        color: var(--color-blue);
        font-weight: bold;

        &:nth-last-child(2) {
            margin-right: auto;
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &.-arrowed {
        font-family: var(--font-default);
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 1.8;
        color: var(--color-blue);
        text-decoration: none;

        &::after {
            content: '';
            transition:
                background .2s ease,
                transform .3s ease;

            display: inline-block;
            width: 1.9rem;
            height: 1.3rem;
            margin-left: .8rem;
            vertical-align: middle;
            transform: translateX(0);

            background-image: url(long-arrow.svg);
            background-size: 1.9rem 1.3rem;

            @supports (mask-repeat: no-repeat) {
                background: currentColor;
                mask-image: url(long-arrow.svg);
                mask-size: 1.9rem 1.3rem;
                mask-repeat: no-repeat;
            }
        }

        &:hover,
        &:focus {
            text-decoration: none;

            &::after {
                transform: translateX(1rem);
            }
        }
    }
}

.summary {
    display: block;
    margin: 0;
    margin-bottom: 4rem;

    font-family: var(--font-default);
    font-weight: 300;
    font-size: 2rem;
    line-height: 1.88;
    color: var(--color-blue);

}

/* downloads */
.downloads {
    display: block;
    width: 100%;
    max-width: 61.1rem;
    margin-bottom: 6.4rem;
    margin-left: auto;
    margin-right: auto;

    @media (--screen-medium-large) {
        margin-bottom: 8.8rem;
    }
}

.downloads__title {
    display: block;
    margin: 0 auto 5rem;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 1.26;
    color: var(--color-black);
    text-align: center;

    @media (--screen-medium-large) {
        font-size: 3.5rem;
    }
}

.downloads__item {
    position: relative;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    min-height: 10rem;
    border-top: .1rem solid var(--color-gray_10);

    &::before {
        content: '';

        position: absolute;
        top: 5rem;
        left: 1.8rem;
        z-index: 1;

        display: block;
        width: 1.9rem;
        height: 2.3rem;
        transform: translateY(-50%);

        color: var(--color-white);
        background-color: currentColor;
        background-repeat: no-repeat;

        background-image: url('download.svg');
        background-size: 1.9rem 2.3rem;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url('download.svg');
            mask-size: 1.9rem 2.3rem;
            mask-repeat: no-repeat;
        }
    }

    &::after {
        content: '';

        position: absolute;
        top: 2.4rem;
        left: 0;
        font-size: 1.6rem;
        z-index: 0;

        display: block;
        width: 5.5rem;
        height: 5.5rem;
        border-radius: 100%;
        background: var(--color-gray_50);
    }

    @media (--screen-mini-small) {
        flex-wrap: wrap;
    }

    @media (--screen-medium-large) {
        padding-left: 10rem;
    }
}
.downloads__part {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 2.4rem 0;
    padding-right: 1rem;

    @media (--screen-mini-small) {
        padding-left: 10rem;
    }
}

.downloads__name {
    display: block;
    margin-bottom: 1rem;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.68;
    color: var(--color-blue);
}

.downloads__meta {
    display: block;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.2;
    color: var(--color-gray_50);
}

.downloads__button {
    transition: all .2s ease;

    cursor: pointer;
    box-sizing: border-box;
    display: block;
    width: 100%;
    margin: 1rem 0 2.4rem;
    padding: 1.6rem 2.8rem;
    border: 0;

    color: var(--color-blue);
    background: none;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;

    background: var(--color-white);
    box-shadow: 0 .2rem .4rem 0 rgba(0, 0, 0, .5);
    border-radius: 2.2rem;

    &:hover,
    &:focus {
        text-decoration: none;

        color: var(--color-white);
        background: var(--color-blue);
    }

    &:active {
        transform: translateY(.2rem);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, .4);
    }

    @media (--screen-medium-large) {
        width: auto;
        margin: 1rem 0;
    }
}

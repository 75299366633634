.image-card-overview {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: calc(100% + 4rem);
    margin-left: -4rem;

    @media (--screen-large) {
        justify-content: space-between;
    }

    &.-always-center {
        justify-content: center;
    }


    // IE11 bugfix
    @media all and (-ms-high-contrast:none) {
        justify-content: flex-start !important;
    }
}

/**
  This is the helper class.
  These are styles that can be applied to any component in general

  All helper classes should be prefixed with .-helper-
 */

.-helper-hide {
    display: none;
}

.-helper-centered-text {
    text-align: center;
}

@for $i from 0 through 10 {
    .-helper-margin-bottom-#{$i} {
        margin-bottom: #{$i}rem !important;
    }
}

.mollie-error-no-api-key {
    background-color: var(--color-red);
    text-align: center;
    padding-bottom: 10px;
    padding-top: 40px;
    margin: 25px;
}

article,
.article {
    box-sizing: border-box;
    display: block;
    width: 100%;
    max-width: 63.1rem; // includes padding
    margin: 0 auto;
    margin-bottom: 8rem;

    padding-left: 1rem;
    padding-right: 1rem;

    > *:last-child {
        margin-bottom: 0;
    }

    p + ul,
    p + ol {
        margin-top: -2rem;
    }

    &:after {
        content: '';
        clear: both;
        float: none;
        overflow: hidden;
        display: block;
    }
}

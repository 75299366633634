/* Button */
%button-reset {
    transition: all .2s ease;

    cursor: pointer;
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    padding: 0;
    border: 0;

    color: var(--color-black);
    background: none;

    font-family: var(--font-default);
    font-weight: var(--font-weight-default);
    font-size: 1.5rem;
    line-height: 1;
    text-decoration: none;
    text-transform: none;

    vertical-align: middle;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

%button,
.button {
    @extend %button-reset;

    color: var(--color-white);
    background: var(--color-blue);
    padding: 1.6rem 2.8rem;

    &.-page-search {
        padding: 1rem 2.8rem;
    }

    &.-nav-search {
        $h: 1.8rem;

        flex-shrink: 0;

        border-radius: 0;
        display: block;
        width: $h;
        height: $h;
        margin: 0;
        padding: 0;
        border: 0;
        box-shadow: none;

        font: 0/0 var(--font-default);
        line-height: 1;

        background-image: url(magnifier.svg);
        background-size: $h $h;

        @supports (mask-repeat: no-repeat) {
            background: var(--color-blue);
            mask-image: url(magnifier.svg);
            mask-size: $h $h;
            mask-repeat: no-repeat;
        }
    }

    .image-card & {
        margin-bottom: 2.5rem;
    }

    .image-card:hover &,
    .image-card:focus & {
        background: var(--color-black);
    }

    &:hover,
    &:focus {
        background: var(--color-black);
    }

    &:active {
        background: var(--color-blue);
    }

    &.-show-hide-password {
        padding: 0.4rem 1rem;
        float: right;
        font-size: 1.3rem;
        font-weight: 400;
        background: var(--color-gray_10);

        &:hover, &:focus {
            background: var(--color-gray_10);
        }
    }

    &.-full-width {
        width: 100%;
        text-align: center;
    }

    &.-no-style,
    &.-no-style:hover,
    &.-no-style:focus {
        border-radius: 0;
        bottom: 0;
        margin: 0;
        padding: 0;
        border: 0;
        background: none;
        color: var(--color-primary);
        font-weight: 400;
    }

    &.-loading[disabled] {
        position: relative;
        color: transparent;

        &:hover, &:focus {
            color: transparent;
        }

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 40px;
            margin: -20px -20px;
            background-color: var(--color-white);
            border-radius: 100%;
            animation: scaleout 1.0s infinite ease-in-out;
        }
    }

    .plack.-flat & {
        margin-bottom: 1rem;

        &:not(:last-child) {
            margin-right: 2rem;
        }
    }

    .testimonial & {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

@keyframes scaleout {
    0% {
        transform: scale(0.0);
    }
    100% {
        transform: scale(1.0);
        opacity: 0;
    }
}

/* Button Converter converts any direct link into a button, without the link having the button class */
/* Due to component scoping this class is placed in here */
.button-converter {
    > a:not([class]) {
        @extend .button;
    }
}

/*Due to the inability of the ck-editor to place .button classes this piece of css needs to be here */
.inline-centered-call-to-action,
.inline-call-to-action {
    a {
        @extend .button;
        vertical-align: middle;
        text-decoration: none;
    }
}

/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
    --color-white: #FFFFFF;
    --color-black: #000000;

    --color-blue: #00609C;

    --color-red: #D73232;

    --color-gray_10: #E1E1E1;
    --color-gray_50: #7f7f7f;

    --container-width: 127.3rem;

    --font-default: 'Open Sans', Helvetica, Arial, sans-serif;
    --font-weight-default: normal;

    --default-side-padding: 2rem;
    --medium-side-padding: 3rem;
    --large-side-padding: 4.7rem;
}

@custom-media --screen-large        (width >= 850px);

@custom-media --screen-medium-large (width >= 630px);
@custom-media --screen-medium       (width >= 630px) and (width < 850px);

@custom-media --screen-small-large  (width >= 420px);
@custom-media --screen-small-medium (width >= 420px) and (width < 850px);
@custom-media --screen-small        (width >= 420px) and (width < 630px);

@custom-media --screen-mini-medium  (width < 850px);
@custom-media --screen-mini-small   (width < 630px);
@custom-media --screen-mini         (width < 420px);


:root {
    --side-padding: var(--default-side-padding);

    @media (--screen-medium) {
        --side-padding: var(--medium-side-padding);
    }

    @media (--screen-large) {
        --side-padding: var(--large-side-padding);
    }
}

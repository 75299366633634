
@import '~@inventis/cookieconsent/cookie-consent';
@import '~dialog-polyfill/dialog-polyfill.css';

.cookie-consent-component {
    + .backdrop,
    &::backdrop {
        background-color: rgba(0,10,18, 0.6);
    }
}

.cookie-consent-component {
    box-sizing: border-box;
    display: block;
    width: 100%;
    max-width: 61.5rem;
    background: var(--color-white);
    border: .9rem solid var(--color-gray-1);
}

.cookie-consent-component__logo {
    display: block;
    width: 100%;
    max-width: 19rem;
    margin: 0 auto;
    margin-bottom: 3rem;
}

.cookie-consent-component__title {
    display: block;
    margin: 0 0 2rem 0;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.25;
    color: var(--color-black-dark);
}

.cookie-consent-component__text {
    display: block;
    margin: 0 0 3.3rem 0;
    font-family: var(--font-default);
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.46;
    color: var(--color-black-dark);
}

.cookie-consent-component__inner {
    margin: 0;
}

.cookie-consent-component__focus-remover {
    position: absolute;
}

/*
dialog + .backdrop {
    background: rgba(0, 0, 0, .4);
}

dialog::backdrop { // Can't be combined with previous, will not work in safari
    background: rgba(0, 0, 0, .4); // working with var() not allowed here
}*/

/*
:root {
    --cookie-consent-bottom: 50%;
    --cookie-consent-max-width: 44em;
    --cookie-consent-transform: translate3d(0, calc(50% + .5px), 0); // Fix blurry rendering in Chrome
    --cookie-consent-padding: 2em;
}

.cookie-consent-component {
    bottom: var(--cookie-consent-bottom);
    box-sizing: border-box;
    max-height: 100%;
    max-width: var(--cookie-consent-max-width);
    overflow-y: auto;
    padding: var(--cookie-consent-padding);
    position: fixed;
    transform: var(--cookie-consent-transform);
    width: 100%;

    &__header, &__buttons, &__footer {
        text-align: center;
    }
}
*/

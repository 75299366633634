.main-image {
    display: block;
    margin: 0 auto;
    max-width: 104rem;
    margin-bottom: 5rem;

    img {
        display: block;
        width: 100%;
        height: auto;
    }
}

.nav {
    display: flex;
    flex-direction: column;

    @media (--screen-large) {
        flex-direction: row;
        justify-content: flex-end;
    }

    &.-main {
        @media (--screen-mini-medium) {
            margin-bottom: 2rem;
        }
    }

    &.-meta {
        @media (--screen-mini-medium) {
            margin-bottom: 2rem;
        }
    }

    &.-page-footer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 6rem;
        margin-right: 5rem;
        min-width: 18.8rem;
    }

    &.-language {
        flex-direction: row;

        @media (--screen-large) {
            position: relative;
            margin-left: 4.5rem;
        }
    }
}

.nav__dropdown {
    display: flex;
    width: 100%;

    @media (--screen-large) {
        position: absolute;
        top: 2.5rem;
        left: 0;
        width: auto;
        display: none;
        background: var(--color-white);
    }

    &:hover,
    &:focus {
        @media (--screen-large) {
            display: block;
        }
    }

    @media (--screen-large) {
        .nav:hover &,
        .nav:focus & {
            display: block;
        }
    }
}

.nav__item {
    position: relative;
    display: inline-block;
    margin-bottom: .8rem;
    text-decoration: none;

    font-family: var(--font-default);
    font-weight: var(--font-weight-default);
    color: var(--color-blue);

    @media (--screen-mini-medium) {
        align-self: flex-start;
    }

    &::after {
        transition: border-bottom-color .2s ease;
        content: '';
        display: block;
        width: 100%;
        margin-top: .5rem;
        border-bottom: .4rem solid transparent;
    }

    &:hover,
    &:focus {
        text-decoration: none;

        &::after {
            border-bottom-color: var(--color-gray_10);
        }
    }

    &.-home {
        @media (--screen-large) {
            display: none;
        }
    }

    &.-active {
        //pointer-events: none;

        &::after {
            border-bottom-color: var(--color-gray_10);
        }
    }

    .nav.-main & {
        font-size: 2.2rem;

        @media (--screen-mini-medium) {
            &::after {
                margin-top: .3rem;
            }
        }

        @media (--screen-large) {
            margin-left: 4rem;
        }

        @media (min-width: 850px) and (max-width: 1220px) {
            &:not(:first-child) {
                margin-left: 2rem;
            }
        }
    }

    .nav.-meta & {
        font-size: 1.5rem;

        &::after {
            margin-top: .2rem;
            border-bottom-width: .3rem;

            @media (--screen-mini-medium) {
                margin-top: 0;
            }
        }

        @media (--screen-mini-medium) {
            margin-bottom: 1.8rem;
        }

        @media (--screen-large) {
            &:not(:first-child) {
                margin-left: 4.5rem;
            }
        }

        @media (min-width: 850px) and (max-width: 1220px) {
            &:not(:first-child) {
                margin-left: 2rem;
            }
        }
    }

    .nav.-language & {
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: bold;

        &::after {
            display: none;
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }

        &:not(:last-child) {
            @media (--screen-mini-medium) {
                margin-right: 2rem;
            }
        }

        @media (--screen-large) {
            margin: 0 0 .5rem 0;
        }

        &.-active {
            order: -1;
            text-decoration: underline;

            @media (--screen-mini-medium) {
                color: var(--color-black);
            }

            @media (--screen-large) {
                padding-right: 1.3rem;
            }

            &::before {
                $h: .5rem;
                transition:
                    background .2s ease,
                    opacity .3s ease;


                position: absolute;
                top: .4rem;
                right: 0;
                z-index: 0;

                display: block;
                width: .8rem;
                height: $h;

                background-image: url(arrow.svg);
                background-size: 100% $h;

                @supports (mask-repeat: no-repeat) {
                    background: currentColor;
                    mask-image: url(arrow.svg);
                    mask-size: 100% $h;
                    mask-repeat: no-repeat;
                }

                @media (--screen-large) {
                    content: '';
                }
            }
        }
    }

    .nav.-page-footer & {
        display: inline-block;
        margin-bottom: 0;
    }
}

.nav__search-button {
    box-sizing: border-box;
    display: none;
    width: 2.2rem;
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    z-index: 1;

    vertical-align: middle;
    cursor: pointer;

    position: relative;
    margin-bottom: 1.5rem;
    text-decoration: none;

    font: 0/0 var(--font-default);
    font-weight: var(--font-weight-default);
    color: var(--color-blue);

    &::before,
    &::after {
        $h: 2.2rem;
        transition:
            background .2s ease,
            opacity .3s ease;

        content: '';

        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        display: block;
        width: 100%;
        height: $h;
        margin-top: .5rem;

        opacity: 0;
        background-size: 100% $h;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-repeat: no-repeat;
            mask-size: 100% $h;
        }
    }

    &::before {
        left: -.1rem;
        background: url(cross.svg) center center no-repeat;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url(cross.svg);
        }
    }

    &::after {
        opacity: 1;
        background: url(magnifier.svg) center center no-repeat;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url(magnifier.svg);
        }
    }

    @media (--screen-mini-medium) {
        align-self: flex-start;
    }

    @media (--screen-large) {
        display: inline-block;
        margin-left: 4.5rem;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        color: var(--color-black);
    }

    .-page-search-is-open & {
        &::before {
            opacity: 1;
        }

        &::after {
            opacity: 0;
        }
    }
}

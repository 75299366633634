%title,
[class^='title-'] {
    display: block;

    -webkit-font-smoothing: auto;
    font-family: var(--font-default);
    font-weight: var(--font-weight-default);
    margin: 0 0 2rem;

    a:not([class]) {
        text-decoration: none;
        color: inherit;
    }
}

.title-display {
    font-size: 2.8rem;
    font-weight: bold;
    line-height: 1.26;
    text-align: center;
    margin-bottom: 2.5rem;
    color: var(--color-blue);

    @media (--screen-medium-large) {
        font-size: 5rem;
        margin-bottom: 4rem;
    }
}

.title-1 {
    font-size: 2.8rem;
    font-weight: bold;
    line-height: 1.26;

    @media (--screen-medium-large) {
        font-size: 3.8rem;
    }
}

.title-2 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.25;

    @media (--screen-medium-large) {
        font-size: 2.8rem;
    }
}

.title-3 {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.38;

    @media (--screen-medium-large) {
        font-size: 2.2rem;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @extend %title;
}

h1 {
    @extend .title-1 !optional;
}

h2 {
    @extend .title-2 !optional;
}

h3 {
    @extend .title-3 !optional;
}

h4 {
    @extend .title-4 !optional;
}

h5 {
    @extend .title-5 !optional;
}

h6 {
    @extend .title-6 !optional;
}

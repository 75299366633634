.nav-search {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0 1.7rem;
    background: var(--color-white);

    @media (--screen-large) {
        display: none;
    }
}
